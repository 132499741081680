var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"gCourse-basics"}},[(_vm.$apollo.loading)?[_c('loading')]:[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-half is-offset-one-quarter"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"School Class"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"School Class","message":errors,"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"id":"school-class","placeholder":"Select a option","expanded":""},model:{value:(_vm.gCourse.schoolClass),callback:function ($$v) {_vm.$set(_vm.gCourse, "schoolClass", $$v)},expression:"gCourse.schoolClass"}},_vm._l((_vm.schoolClasses),function(schoolClass){return _c('option',{key:schoolClass.id},[_vm._v(" "+_vm._s(schoolClass.name)+" ")])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Subject","message":errors,"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"id":"subject","name":"subject","placeholder":"Select a option","expanded":""},model:{value:(_vm.gCourse.subject),callback:function ($$v) {_vm.$set(_vm.gCourse, "subject", $$v)},expression:"gCourse.subject"}},_vm._l((_vm.subjects),function(subject){return _c('option',{key:subject.id},[_vm._v(" "+_vm._s(subject.name)+" ")])}),0)],1)]}}],null,true)}),_c('b-field',[_c('p',{staticClass:"control has-text-right"},[_c('a',{staticClass:"button btn-120 is-primary",on:{"click":function($event){return passes(_vm.submit)}}},[_vm._v("Submit")])])])],1)])]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }