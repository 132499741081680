<template>
  <div id="gCourse-basics">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <ValidationProvider
              rules="required"
              name="School Class"
              v-slot="{ errors, valid }"
            >
              <b-field
                label="School Class"
                :message="errors"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
              >
                <b-select
                  id="school-class"
                  v-model="gCourse.schoolClass"
                  placeholder="Select a option"
                  expanded
                >
                  <option
                    v-for="schoolClass in schoolClasses"
                    :key="schoolClass.id"
                  >
                    {{ schoolClass.name }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              name="Subject"
              v-slot="{ errors, valid }"
            >
              <b-field
                label="Subject"
                :message="errors"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
              >
                <b-select
                  id="subject"
                  v-model="gCourse.subject"
                  name="subject"
                  placeholder="Select a option"
                  expanded
                >
                  <option v-for="subject in subjects" :key="subject.id">
                    {{ subject.name }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <b-field>
              <p class="control has-text-right">
                <a class="button btn-120 is-primary" @click="passes(submit)"
                  >Submit</a
                >
              </p>
            </b-field>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { fetchAppUrl, fetchUser, fetchTerm } from '@/assets/js/app_info.js'
import { crudNotification } from '@/assets/js/notification'
import StoreManager from '@/store/store_manager'

export default {
  name: 'ate_basics',
  data() {
    return {
      url: null,
      schoolId: null,
      pageTitle: 'New Course',
      assignedSchoolClassesQuery: true,
      user: null,
      gCourse: {
        schoolClassId: null,
        schoolClass: null,
        subjectId: null,
      },
      assignedSchoolClasses: [],
      schoolClasses: [],
      subjects: [],
      error: null,
    }
  },
  apollo: {
    assignedSchoolClasses: {
      query() {
        if (this.user) {
          return gql`
            query assignedSchoolClassesWithSubjectsForGCourse($id: ID!) {
              assignedSchoolClassesWithSubjects(id: $id) {
                subjectsGroupedBySchoolClass
              }
            }
          `
        }
      },
      variables() {
        return { id: this.user.id }
      },
      update: (data) => data.assignedSchoolClassesWithSubjects,
      error(error) {
        // console.log("errors", error.graphqlErrors);
        this.error = JSON.stringify(error.message)
      },
      skip() {
        return this.assignedSchoolClassesQuery
      },
    },
  },
  watch: {
    assignedSchoolClasses(value) {
      value.subjectsGroupedBySchoolClass.forEach((data) => {
        this.schoolClasses.push({ id: data.id, name: data.name })
      })
      this.assignedSchoolClassesQuery = true
    },
    'gCourse.schoolClass'(value) {
      this.subjects = []
      if (!value) return
      if (!this.assignedSchoolClasses.subjectsGroupedBySchoolClass) return
      const schoolClass = this.assignedSchoolClasses.subjectsGroupedBySchoolClass.filter(
        (data) => data.name === value
      )[0]

      this.gCourse.schoolClassId = schoolClass.id

      this.skipQuery = false

      schoolClass.subjects.forEach((data) => {
        this.subjects.push({ id: data.id, name: data.name })
      })
    },
    'gCourse.subject'(value) {
      this.gCourse.subjectId = this.subjects.filter(
        (data) => data.name === value
      )[0].id
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    submit() {
      fetch(`${this.url}/g_courses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(this.gCourse),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            crudNotification(true, data.errors)
          } else {
            crudNotification([], 'Successfully created')
            this.$router.push(
              `/school/${this.schoolId}/g_courses/${this.gCourse.schoolClassId}`
            )
          }
        })
        .catch((error) => {
          // console.log(error);
        })
    },
  },
  mounted() {
    this.schoolId = this.$route.params.school_id

    this.$store.commit('setPageTitle', this.pageTitle)

    fetchAppUrl().then((url) => {
      this.url = url
    })

    fetchUser().then((user) => {
      this.user = user
      this.gCourse.user_id = user.id
      this.assignedSchoolClassesQuery = false
    })

    this.$store.commit('setSubMenus', [
      {
        name: 'New Ate',
        route: `/school/${this.schoolId}/new_ate`,
      },
    ])
  },
}
</script>

<style scoped></style>
